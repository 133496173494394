<template>
  <PageWithHeader>
    <template #title>
      <t path="Ganesh_2">Happy Ganesh Chaturthi</t>
    </template>
    <div class="main-body">
      <img class="main-bg" src="/img/events/ganeshChaturthi/bg_main@2x.png"/>
      <div class="p-16">
        <t as="p" class="c-white pb-24" :args="{gift: winGift}" path="Ganesh_10" style="line-height: 20px;" v-if="isWin">
          Congratulations, you have received $500 coupons which will be issued to your account the day after the event ends. Thank you for your participation and patience.
        </t>

        <div class="df-center img-btn">
          <t class="f-bold c-white t-title" path="Ganesh_3">Gifts</t>
        </div>

        <div class="gifts-wrap py-16">
          <div :class="['gift-item df-middle mb-8 p-16', {'gift-item-check':item.chooseStatus}]" v-for="(item, index) in giftList" :key="index">
            <Pic :src="item.url" width="80" height="80"/>
            <div class="item-right flex-1 pl-24 bg-theme df-col df-center" style="height: 80px;">
              <p class="c-text f-nm f-bold mb-4" style="line-height:24px;">{{ item.giftName }}</p>
              <p class="f-md mb-4" style="line-height:16px;">
                <t class="c-title ta-ti" path="Ganesh_4"></t>
                <money class="f-medium" :v="item.depositAmount" />
              </p>
              <p class="f-md" style="line-height:16px;">
                <t class="c-title ta-ti" path="Ganesh_5">Trade</t>
                <money class="f-medium" :v="item.tradeAmount" />
              </p>
              <Pic src="events/check" v-if="item.chooseStatus" class="check-status" width="24" height="24"/>
            </div>
          </div>
        </div>

        <div class="df-center mt-24 img-btn">
          <t class="f-bold c-white t-title" path="Ganesh_6">My progress</t>
        </div>

        <div class="cur-info df-middle mt-12 px-24 py-16">
          <p class="flex-1">
            <t class="ci-p1 mr-12" path="Ganesh_7">Trade</t>
            <money class="f-medium f-nm" style="color: #00FF1E;" :v="prop('rechargeAmount', data)" />
          </p>
          <p class="flex-1">
            <t class="ci-p1 mr-12" path="Ganesh_8">Trade</t>
            <money class="f-medium f-nm" style="color: #00FF1E;" :v="prop('mtTradeAmount', data)" />
          </p>
        </div>
        <div class="py-24">
          <t as="div" path="Ganesh_9" multiline #="{td}" custom>
            <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
        </div>
      </div>
    </div>
  </PageWithHeader>
</template>
<script>
import { reactive, shallowRef } from '@vue/reactivity'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import { readRmInfo, readGiftList } from '@/events/christmas/christmas.api'

export default {
  name: 'Christmas',
  components: { PageWithHeader, Pic },
  setup () {
    const data = reactive({
      rechargeAmount: 0,
      mtTradeAmount: 0,
    })
    const isWin = shallowRef(false)
    const winGift = shallowRef('')
    const giftList = shallowRef()

    // 获取礼物列表
    const getGiftList = () => {
      readGiftList().then(res => {
        res.forEach(item => {
          if (item.chooseStatus === 1) {
            isWin.value = true
            winGift.value = item.giftName
          }
        })
        giftList.value = res
      })
    }
    getGiftList()

    // 查询充值记录
    const getInfo = () => {
      readRmInfo().then(res => {
        data.rechargeAmount = res.rechargeAmount
        data.mtTradeAmount = res.mtTradeAmount
      })
    }
    getInfo()
    return {
      isWin,
      winGift,
      data,
      giftList,
    }
  },
}
</script>
<style scoped lang="scss">
.main-body{
  background-color: #330061;
  .main-bg{
    width: 100%;
    height: 91.2vw;
  }
  .img-btn{
    margin: auto;
    .t-title{
      background: #FFEF00;
      border-radius: 16px;
      display: flex;
      min-width: 128px;
      height: 32px;
      align-items: center;
      justify-content: center;
      color: #330061;
    }
  }
  .gifts-wrap{
    margin: auto;
    .gift-item{
      margin: auto;
      margin-bottom: 8px;
      background-image: url(/img/events/ganeshChaturthi/bg_gift@2x.png);
      background-color: rgb(222, 1, 65);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .item-right{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .check-status{
        position: absolute;
        right: 32px;
      }
    }
    .gift-item-check{
      background-image: url(/img/events/ganeshChaturthi/chose_bg@2x.png);
    }
  }
  .cur-info{
    background-image: url(/img/events/ganeshChaturthi/bg_bt@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .ci-p1{
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      line-height: 24px;
    }
  }
}
.ta-ti{
  display: inline-block;
  width: 60px;
}
</style>
